<template>
  <bcf-aside
    class="editprebookitem"
    @close="close"
  >
    <form
      style="display: contents"
      @submit.prevent="sendOrderUpdate"
    >
      <bcfAsideHeader
        :close="close"
        class="editprebookitem__header"
      >
        {{ $t('account.account.myBikeOrders.editPrebookItem.title') }}
        <template v-slot:subtitle>
          <h2>PRE-ORDER / {{ orderId }}</h2>
        </template>
      </bcfAsideHeader>
      <bcfAsideContent class="editprebookitem__content">
        <div v-if="!loaded">
          <loader class="m-b-2" />
        </div>
        <div v-else-if="errorMessage">
          <Message
            type="error"
            :icon="true"
            :content="$t(`api.${errorMessage}`)"
          />
        </div>
        <div v-else-if="!canChangeBike">
          <Message
            type="update"
            :icon="false"
            :content="$t(`account.account.myBikeOrders.editPrebookItem.cantchange`)"
          />
        </div>
        <div v-else>
          <div class="editprebookitem__content__section editprebookitem__content__section--details">
            <div class="editprebookitem__properties">
              <div class="editprebookitem__property">
                <div class="editprebookitem__property__title">
                  {{ $t('account.account.myBikeOrders.editPrebookItem.model') }}
                </div>
                <div class="editprebookitem__property__value">
                  {{ item.attributes.model }} / {{ item.attributes.groupset }}
                </div>
              </div>
              <div class="editprebookitem__property">
                <div class="editprebookitem__property__title">
                  {{ $t('account.account.myBikeOrders.editPrebookItem.deliverydate') }}
                </div>
                <div class="editprebookitem__property__value">
                  {{ formatDate(etd) }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="editprebookitem__content__section editprebookitem__content__section--changes"
          >
            <div
              v-if="sizeVariants"
              class="sizechanges"
            >
              <SectionTitle><span>{{ $t('account.account.myBikeOrders.editPrebookItem.changesize') }}<small class="m-l-1 text-danger">({{ $t('account.account.myBikeOrders.editPrebookItem.currentsize') }}: {{ item.variantattribute.value }})</small></span></SectionTitle>
              <Multiselect
                :options="sizeVariants.options"
                :searchable="false"
                :show-labels="false"
                :value="sizeVariants.current"
                track-by="itemId"
                class="pointer"
                @select="setToItem"
              >
                <template
                  slot="singleLabel"
                  slot-scope="props"
                >
                  {{ props.option.title }}
                </template>
                <template
                  slot="option"
                  slot-scope="props"
                >
                  <div>
                    <span style="display: inline-block; min-width: 55px;">{{ props.option.title }}</span>
                    <small>{{ props.option.small }}</small>
                  </div>
                </template>
              </Multiselect>
            </div>
            <div
              v-if="designVariants"
              class="designchanges"
            >
              <SectionTitle>{{ $t('account.account.myBikeOrders.editPrebookItem.changedesign') }}</SectionTitle>
              <div
                class="select-options designchange-options"
              >
                <div
                  v-for="(option) in designVariants.options"
                  :key="option.relationId"
                  class="select-options__item"
                  :class="{'select-options__item--active': option.relationId === 'current'}"
                  @click="setToItem(option)"
                >
                  <div
                    v-if="option.different"
                    style="position: absolute; bottom: 0; right: 0; background: white; color: white; width: 100%; height: 100%; opacity: 50%;"
                  />
                  <img
                    v-if="option.image"
                    :src="option.image"
                  >
                  <img
                    v-else
                    src="@/assets/img/product_placeholder.jpg"
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="editprebookitem__content__section editprebookitem__content__section--overview"
          >
            <SectionTitle>{{ $t('account.account.myBikeOrders.editPrebookItem.overview') }}</SectionTitle>
            <div class="overview-images">
              <div class="overview-image overview-image--current">
                <img
                  :src="item.image"
                  alt="current design"
                >
                <span class="badge badge--size">
                  {{ $t('account.account.myBikeOrders.editPrebookItem.overviewsize') }}: {{ item.variantattribute.value }}
                </span>
                <span class="badge">
                  {{ $t('account.account.myBikeOrders.editPrebookItem.overviewcurrent') }}
                </span>
              </div>
              <div class="overview-image overview-image--new">
                <img
                  :src="toItem?.image || item.image"
                  alt="current design"
                >
                <span class="badge badge--size">
                  {{ $t('account.account.myBikeOrders.editPrebookItem.overviewsize') }}: {{ activeItem.variantattribute.value }}
                </span>
                <span class="badge">
                  {{ $t('account.account.myBikeOrders.editPrebookItem.overviewnew') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </bcfAsideContent>
      <bcfAsideFooter
        style="justify-content: stretch"
        class="editprebookitem__footer"
      >
        <button
          class="button button--primary"
          :disabled="!hasOrderUpdate || saveInProgress"
          type="submit"
          @click.prevent="saveOrderUpdate"
        >
          {{ $t('account.account.myBikeOrders.editPrebookItem.save') }}
        </button>
      </bcfAsideFooter>
    </form>
    <LightBox
      ref="lightbox"
      :images="lightboxImage"
      :show-caption="false"
      :show-light-box="false"
      :show-thumbs="false"
      background-color="white"
    />
  </bcf-aside>
</template>
<script>
import loader from '@/elements/loader.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import Message from '@/components/Message.vue';
import LightBox from '@/components/LightBox/LightBox.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import dialogs from '@/utils/dialogs';
import {
  getOrderDetail, GetItemRelations, GetItemByid, GetItemByExternalId, changePrebookBike,
} from '@/api/api';
import { formatDate } from '@/utils/dateUtils';
import { availabilityText } from '@/utils/cartUtils';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    Message,
    LightBox,
    SectionTitle,
    loader,
    Multiselect,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    useCloseEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      errorMessage: '',
      order: {},
      lightboxImage: [],
      orderUpdated: false,
      updateError: null,
      inProgress: false,
      previousroute: null,
      item: null,
      toItem: null,
      sizeVariants: null,
      designVariants: null,
      orderLimitInDays: 10,
      saveInProgress: false,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasOrderUpdate) {
      const confirmed = await dialogs.show({
        title: this.$t('general.unsavedChanges.title'),
        content: this.$t('general.unsavedChanges.content'),
        type: 'cancelYes',
      });
      if (confirmed) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    etd() {
      const etd = this.orderline?.promiseddeliverydate || this.orderline?.requesteddeliverydate;
      return etd;
    },
    canChangeBike() {
      const now = new Date();
      const etd = new Date(this.etd);
      const diffDays = Math.round((now - etd) / (1000 * 60 * 60 * 24));
      return diffDays <= this.orderLimitInDays;
    },
    activeItem() {
      return this.toItem || this.item;
    },
    hasOrderUpdate() {
      return this.toItem && this.toItem.id !== this.item.id && !this.orderUpdated;
    },
    orderlineId() {
      return Number(this.$route.params.orderlineId);
    },
    orderline() {
      return this.order.orderlines.find((ol) => ol.orderline === this.orderlineId);
    },
    orderlineBikeIndex() {
      return Number(this.$route.params.orderlineBikeIndex);
    },
  },
  async mounted() {
    this.previousroute = this.$store.state.global.previousRoute;
    await this.fetchData();
  },
  methods: {
    async setToItem(item) {
      try {
        // this.loaded = false;
        await this.fetchItemAndVariants(item.itemId);
        // this.loaded = true;
      } catch (error) {
        // this.loaded = true;
        this.errorMessage = error.message;
      }
    },
    formatDate(dte) {
      return formatDate(dte);
    },
    resetOrderUpdate() {
      this.toItem = null;
      this.fetchItemAndVariants();
    },
    async saveOrderUpdate() {
      // just in case
      if (!this.canChangeBike) return;

      this.saveInProgress = true;

      console.log('Updating orderline', this.orderline.orderline_bike_index, this.orderline);
      console.log('With item', this.toItem);

      try {
        const itemInfo = {
          description: this.toItem.description,
          image: this.toItem.image,
          ean: this.toItem.attributes.ean,
          model: this.toItem.attributes.model,
          size: this.toItem.attributes.frame_size,
          design: this.toItem.attributes.paint_des,
          groupset: this.toItem.groupset,
        };
        await changePrebookBike(this.order.id, this.order.externalorderid, this.orderlineId, this.orderlineBikeIndex, this.item.externalid, this.toItem.externalid, itemInfo);
        this.$emit('updateOverview');
        this.orderUpdated = true;
        this.close();
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.saveInProgress = false;
      }
    },
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
    close() {
      if (this.useCloseEvent) {
        this.$emit('close');
      } else if (this.previousroute !== null) {
        this.$router.push(this.previousroute);
      } else {
        this.$router.push({ name: 'myBikeOrders' });
      }
    },
    async fetchData() {
      try {
        const customerId = this.$store.state.customer.selectedCustomerId;
        const lang = this.$store.state.language.currentApi;

        const orderResponse = await getOrderDetail(
          customerId,
          this.orderId,
        );
        this.order = orderResponse.result;

        this.item = await GetItemByExternalId(
          this.getExternalItemId(),
          lang,
          customerId,
        );

        console.log('Item', this.item);
        const variants = await GetItemRelations(
          this.item.id,
          lang,
          customerId,
          true,
        );
        this.updateVariants(variants);
        this.loaded = true;
      } catch (error) {
        this.loaded = true;
        this.errorMessage = error.message;
      }
    },
    async fetchItemAndVariants(itemId) {
      const customerId = this.$store.state.customer.selectedCustomerId;
      const lang = this.$store.state.language.currentApi;
      const itemReq = GetItemByid(
        itemId,
        lang,
        customerId,
      );
      const relationsReq = GetItemRelations(
        itemId,
        lang,
        customerId,
        true,
      );

      const [item, relations] = await Promise.all([itemReq, relationsReq]);
      this.toItem = item;
      this.updateVariants(relations);
    },
    getExternalItemId() {
      return this.orderline.externalid;
    },
    openLightboxImage(ol, event) {
      if (!event.target.failed) {
        if (event.preventDefault) event.preventDefault();
        if (event.stopPropagation) event.stopPropagation();

        const image = {
          thumb: ol.image ? ol.image : `https://images.cyclingfactory.be/${ol.externalid}_image_400x.jpg`,
          src: ol.image ? ol.image : `https://images.cyclingfactory.be/${ol.externalid}_image_2048x.jpg`,
          caption: ol.description,
        };
        this.lightboxImage = [{
          ...image,
          key: 'product',
        }];
        this.$refs.lightbox.showImage(0);
      }
    },
    updateVariants(variants) {
      this.variants = variants;
      this.updateSizeVariants();
      this.updateDesignVariants();
    },
    updateSizeVariants() {
      if (this.variants && this.variants.othersizes && this.activeItem.variantattribute) {
        const currentSize = {
          relationId: 'current',
          itemId: this.activeItem.id,
          title: this.activeItem.variantattribute.value,
          externalid: this.activeItem.externalid,
          description: this.activeItem.description,
          price: this.activeItem.$$price.price,
          priceDifference: null,
          small: availabilityText(this.activeItem.$$availability, this.showRetailPrices),
          preorder: this.activeItem.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(this.activeItem.attributes.preorder.toLowerCase()),
        };
        let othersizes = [
          ...this.variants.othersizes ? this.variants.othersizes.map((o) => ({
            relationId: o.relationId,
            itemId: o.itemId,
            title: o.variantattribute.value,
            externalid: o.externalid,
            description: o.description,
            price: o.$$price.price,
            small: availabilityText(o.$$availability, this.showRetailPrices),
            preorder: o.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(o.attributes.preorder.toLowerCase()),
          })) : [],
          currentSize,
        ];
        othersizes = othersizes.sort((a, b) => {
          const sizes = [
            'xxxs',
            'xxs',
            'xs',
            's',
            'm',
            'l',
            'xl',
            'xxl',
            'xxxl',
          ];

          if (
            sizes.indexOf(a.title.toLowerCase()) > -1
            && sizes.indexOf(b.title.toLowerCase()) > -1
          ) {
            return (
              sizes.indexOf(a.title.toLowerCase())
              - sizes.indexOf(b.title.toLowerCase())
            );
          } if (a.title > b.title) {
            return 1;
          } if (a.title < b.title) {
            return -1;
          }
          return 0;
        });

        this.sizeVariants = {
          title: this.$t('items.availableSizes'),
          current: currentSize,
          titleAttribute: 'frame_size',
          type: 'select',
          options: othersizes.map((o, i) => ({
            ...o,
            index: i,
            type: 'othersizes',
          })),
        };
      } else {
        this.sizeVariants = null;
      }
    },
    updateDesignVariants() {
      if (this.variants?.otherdesigns && this.variants.otherdesigns.length > 0) {
        let otherdesigns = [
          ...this.variants.otherdesigns ? this.variants.otherdesigns.map((o) => ({
            relationId: o.relationId,
            itemId: o.itemId,
            title: o.description,
            image: o.image ? o.image : '',
            externalid: o.externalid,
            description: o.description,
            price: o.$$price.price,
            paint_des: o.attributes ? o.attributes.paint_des : null,
            preorder: o.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(o.attributes.preorder.toLowerCase()),
          })) : [],
          {
            relationId: 'current',
            itemId: this.activeItem.id,
            title: this.activeItem.description,
            image: this.activeItem.image ? this.activeItem.image : '',
            externalid: this.activeItem.externalid,
            description: this.activeItem.description,
            price: this.activeItem.$$price.price,
            paint_des: this.activeItem.attributes ? this.activeItem.attributes.paint_des : null,
            preorder: this.activeItem.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(this.activeItem.attributes.preorder.toLowerCase()),
          },
        ];
        // remove duplicates
        otherdesigns = otherdesigns.filter((value, index, self) => index === self.findIndex((t) => (
          t.paint_des === value.paint_des
        )));
        otherdesigns = otherdesigns.sort((a, b) => a.paint_des.localeCompare(b.paint_des));
        // otherdesigns = otherdesigns.sort((a, b) => ((a.different === b.different) ? 0 : a.different ? 1 : -1));
        this.designVariants = {
          title: this.$t('items.designVariations'),
          titleAttribute: 'color',
          type: 'images',
          options: otherdesigns.map((o, i) => ({
            ...o,
            index: i,
            type: 'otherdesigns',
          })).sort((o) => !o.different),
        };
      } else {
        this.designVariants = null;
      }
    },
  },
};
</script>
